import React from "react";
import Header from "../components/header";
import {Helmet} from "react-helmet";
import "../styles/index.css";
import {Link} from "gatsby";

function Index() {
  return (
    <main>
      <Helmet>
        <title>User Research</title>
      </Helmet>

      <Header/>

      <section className="bg-ixdf">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 order-2 order-lg-1">
              <h1 className="text-white">User Research</h1>
              <p className="text-white">
                I begin every research project with the same four questions
              </p>

              <ol className="text-white">
                <li>
                  Is the research outcome a specific design solution? A product? A theory
                  of our user’s motivations?
                </li>
                <li>
                  Is the audience a UX Designer? A company? A research community?
                </li>
                <li>
                  What are the constraints for our budget, schedule, staffing and recruitment?
                </li>
                <li>
                  Should this be a one-off study, or part of a Research Ops process?
                </li>

              </ol>

              <p className="text-white">
                The methods, scope and deliverables of each research project are determined by the answers to these
                questions.
              </p>

              {/*<p>
                For the Choir Product Research (see below), the design/dev team needed specific insights on how to help
                a highly-involved group of users find relevant content, so we decided to conduct cognitive walkthroughs
                and user surveys.
              </p>

              <p >
                For the RideAmigos Admin Redesign, our dev team needed a broad idea of how our users think about
                navigation on the platform, and since the users were a very small, overly-busy group we opted to conduct
                a single focus group and a couple of structured interviews.

                Below is a partial list of user research projects I have participated in or led.
              </p>*/}


            </div>

          </div>
        </div>
      </section>




      {/* <!-- content --> */}

      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">RideAmigos Admin Redesign</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Interviews</span>
                  <span className="badge bg-light text-dark">Focus groups</span>
                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led evaluative research with Admin users
                    </li>
                    <li>
                      Conducted structured interviews and a focus group to understand user needs and usability issues
                    </li>
                    <li>
                      Used results guide changes to the Rideamigos desktop application by the Product and Dev teams
                    </li>
                    <li>
                      Stakeholders to convince: Product leadership, dev team
                    </li>
                  </ul>
                  <Link to="/admin-networks-interface">See the full case study</Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">RideAmigos Commute Tracker Mobile Trip Planning</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Cognitive walkthroughs</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in evaluative research with commuters
                    </li>
                    <li>
                      Conducted cognitive walkthroughs to understand user needs and usability issues
                    </li>
                    <li>
                      Used results to guide design of mobile application user flow for Product, Leadership and Dev teams
                    </li>

                  </ul>
                  <Link to="/mobile-trip-planning">See the full case study</Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Choir Product Research</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Cognitive walkthroughs</span>
                  <span className="badge bg-light text-dark">Participatory Design</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in generative and evaluative research with hundreds of learners
                    </li>
                    <li>
                      Conducted surveys with learners to understand user experience on Choir platform
                    </li>
                    <li>
                      Used Google Analytics to understand user flow patterns
                    </li>
                    <li>
                      Used results to persuade investors, experts, learners and guide changes in Choir product design and development
                    </li>
                  </ul>
                </div>
              </div>
            </div>



            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Choir Expert Interviews</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Structured Interviews</span>
                  <span className="badge bg-light text-dark">Research Ops</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in generative and evaluative research with 50+ experts
                    </li>
                    <li>
                      100+ hours of structured interviews (including novel drawing exercise) to uncover expert practices
                    </li>
                    <li>
                      Used the results to convince investors, experts, learners and guide changes in Choir product design and development
                    </li>
                    <li>
                      Also used results as material for TEDx talk and other presentations
                    </li>
                  </ul>
                </div>
              </div>
            </div>



            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Bricks and Clicks: Participatory Organizational Design through Microparticipation</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Participatory Design</span>
                  <span className="badge bg-light text-dark">Workshops</span>
                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in generative and evaluative research with 10+ small businesses
                    </li>
                    <li>
                      Conducted workshops and onsite prototype testing of hypothesis that customers and employees could collaborate on business strategy
                    </li>
                    <li>
                      Presented findings to participants to business participants and at Social Informatics conference
                    </li>
                  </ul>
                  <a href="https://dl.acm.org/doi/abs/10.5555/1795234.1795290" target="_blank">Download the published research</a>

                </div>
              </div>
            </div>



            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Digital Fluency Book Research</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Field Research</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in field research with 10+ organizations
                    </li>
                    <li>
                      Conducted field research and structured interviews to understand digital fluency in digital collaboration
                    </li>
                    <li>
                      Used the results as the basis for presentations and a book
                    </li>
                  </ul>
                  <a href="https://www.amazon.com/Digital-Fluency-Building-Success-Age/dp/0615642942" target="_blank"><i>Digital Fluency: Building Success in the Digital Age</i></a>
                </div>
              </div>
            </div>


            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Age-related Differences in Online Social Networking</h5>
                  <span className="badge bg-light text-dark">Co-Researcher</span>
                  <span className="badge bg-light text-dark">Network Analysis</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Worked with a researcher to investigate age-related differences in 35,000 MySpace users
                    </li>
                    <li>
                      Developed a custom web crawler and performed analysis on dataset
                    </li>
                    <li>
                      Used the results as the basis for an academic paper
                    </li>
                  </ul>
                  <a href="https://www.researchgate.net/publication/228533584_Age-related_Differences_in_Online_Social_Networking" target="_blank">Download the published research</a>
                </div>
              </div>
            </div>




            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">IU Communications Digital Fluency Project</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Structured Interviews</span>
                  <span className="badge bg-light text-dark">Workshops</span>
                  <span className="badge bg-light text-dark">Surveys</span>
                  <span className="badge bg-light text-dark">Survey Tool Design/Dev</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in evaluative research and training with all parts of IU’s Communications staff and leadership
                    </li>
                    <li>
                      Conducted structured interviews, surveys and reports to identify the organization's Digital Fluency strengths/weaknesses
                    </li>
                    <li>
                      Results were shared with the entire organization and used to design a digital fluency workshops
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Zappos Digital Reflective Practice Research</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Surveys</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in evaluative research with 200+ Zappos employees
                    </li>
                    <li>
                      Conducted surveys to understand Zappos employees use of social media as reflective practice
                    </li>
                    <li>
                      Statistical analysis was conducted and presented to Zappos HR department
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">BigTreeTop Product Research</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Structured interviews</span>
                  <span className="badge bg-light text-dark">Workshops</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in generative and evaluative research with 50+ small business owners
                    </li>
                    <li>
                      Conducted structured interviews and group workshops with small business owners to discover needs for connecting with customers
                    </li>
                    <li>
                      Cognitive walkthroughs to understand to understand user flow patterns
                    </li>
                    <li>
                      Used Google Analytics
                    </li>
                    <li>
                      Results were used by Dev team to inform the design and redesign of BigTreeTop platform

                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Ahold Global/Stop&Shop KPI Dashboard Research</h5>
                  <span className="badge bg-light text-dark">Researcher</span>
                  <span className="badge bg-light text-dark">Workshops</span>
                  <span className="badge bg-light text-dark">Business Analysis</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Conducted generative research with General Managers of the top 10 performing Stop&Shop supermarkets in New England and Ahold Global leadership
                    </li>
                    <li>
                      Conducted structured interviews and workshops to identify critical KPI’s for inclusion on Business Intelligence dashboards
                    </li>
                    <li>
                      Results were used to build business intelligence dashboards for stakeholders at Stop&Shop and Ahold Global
                    </li>
                  </ul>
                </div>
              </div>
            </div>



            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Walmart Mobile Executive Dashboards</h5>
                  <span className="badge bg-light text-dark">Researcher</span>
                  <span className="badge bg-light text-dark">Structured Interviews</span>
                  <span className="badge bg-light text-dark">Business Analysis</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Conducted structured interviews with managers responsible for national executive visits
                    </li>
                    <li>
                   Identified KPI’s that would be deliverable via mobile interface to Walmart executives on weekly onsite store visits
                    </li>
                    <li>
                      Dashboard delivered to team and used by executives
                    </li>
                  </ul>
                </div>
              </div>
            </div>




            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">SociaLens Digital Fluency Assessment Tool Research</h5>
                  <span className="badge bg-light text-dark">Lead Researcher</span>
                  <span className="badge bg-light text-dark">Surveys</span>
                  <span className="badge bg-light text-dark">Survey Tool Design/Dev</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Led team in generative and Evaluative research with 100+ employees across several organizations
                    </li>
                    <li>
                     Developed and tested a survey-based tool for assessing Digital Fluency-related organizational factors
                    </li>
                    <li>
                      Results were presented back to all organizational participants
                    </li>
                  </ul>
                </div>
              </div>
            </div>



            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">5/3 Bank Loan Officer Research</h5>
                  <span className="badge bg-light text-dark">Usability Researcher</span>
                  <span className="badge bg-light text-dark">Structured Interviews</span>
                  <span className="badge bg-light text-dark">Heuristic Evaluation</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Conducted structured interviews with fifteen Loan Officers for 5/3 Bank
                    </li>
                    <li>
                      Interviews and Heuristic Evaluation used to inform platform usability improvements
                    </li>
                  </ul>
                </div>
              </div>
            </div>


{/*
            <div className="col-sm-6 col-lg-4 mb-4">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Project</h5>
                  <span className="badge bg-light text-dark">Role</span>
                  <span className="badge bg-light text-dark">Method</span>

                </div>
                <div className="card-body">
                  <ul>
                    <li>
                      Role and goal of research
                    </li>
                    <li>
                      Methods and reasons for choosing
                    </li>
                    <li>
                      Outome and audience
                    </li>
                  </ul>
                </div>
              </div>
            </div>*/}


          </div>
        </div>



      </section>




      <div class="lightbox-target" id="admin-ux-drawing">
        <img src="/static/admin-ux-drawing.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="research-animated">
        <img src="/static/mobile-trip-planning-research-animated.gif"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="insights">
        <img src="/static/mobile-trip-planning-insights.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="prototypes">
        <img src="/static/mobile-trip-planning-prototypes.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="testing">
        <img src="/static/mobile-trip-planning-testing.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>

      <div class="lightbox-target" id="conclusions">
        <img src="/static/mobile-trip-planning-conclusions.png"/>
        <a class="lightbox-close" href="#"></a>
      </div>
    </main>
  );
}

export default Index;
